// Gatsby supports TypeScript natively!
import React from "react"
import { Link } from "gatsby"

import { rhythm } from "../../utils/typography"
import styled from "styled-components"
import Img from "gatsby-image"
import { SizeMD } from "../Merry/vars"

const Article = styled.article`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 32px;

@media screen and (min-width: ${SizeMD}) {
    flex-direction: row;
    align-items: center;
    margin-bottom: 0;
}
`

const ThumbnailContainer = styled.div`
    width: 100%;
    min-width: 100%;
    max-height: 300px;
    overflow: hidden;
    margin-right: 16px;

@media screen and (min-width: ${SizeMD}) {
    width: 200px;
    min-width: 200px;
    max-height: 140px;
}
`


interface ItemProps {
    slug: string
    title: string
    date: string
    description?: string
    excerpt: string
    image?: any
}

const Item = ({ slug, image, title, date, description, excerpt }: ItemProps) => {
  return (
    <Article key={slug}>
        <ThumbnailContainer>
            {
                image &&
                <Img fluid={image} />
            }
        </ThumbnailContainer>
        <section>
            <header>
                <h3
                style={{
                    marginBottom: rhythm(1 / 4),
                    marginTop: rhythm(1),
                }}
                >
                <Link style={{ boxShadow: `none` }} to={`/project${slug}`} state={{ navOpen: false}}>
                    {title}
                </Link>
                </h3>
                <small>{date}</small>
            </header>
            <section>
                <p
                dangerouslySetInnerHTML={{
                    __html: description || excerpt,
                }}
                />
            </section>
        </section> 
    </Article>
  )
}

export default Item

