import React from "react"
import { PageProps, graphql } from "gatsby"

import Item from "../components/Projects/Item"
import PageWrap from "../components/PageWrap"
import { Main } from "../components/Merry/Layout"

type Data = {
  site: {
    siteMetadata: {
      title: string
    }
  }
  allMarkdownRemark: {
    edges: {
      node: {
        excerpt: string
        frontmatter: {
          title: string
          date: string
          description: string
          featuredImage: {
            childImageSharp: {
              fluid: any
            }
          }
        }
        fields: {
          slug: string
        }
      }
    }[]
  }
}

const ProjectsIndex = ({ data, location }: PageProps<Data>) => {
  const posts = data.allMarkdownRemark.edges

  return (
    <PageWrap
      title="Projects"
      description=""
      navOpen={true}
    >
      <Main>
        {posts.map(({ node }) => {
          const title = node.frontmatter.title || node.fields.slug
          const img = node.frontmatter.featuredImage && node.frontmatter.featuredImage.childImageSharp && node.frontmatter.featuredImage.childImageSharp.fluid
          return (
            <Item
              key={node.fields.slug}
              slug={node.fields.slug}
              image={img}
              title={title}
              date={node.frontmatter.date}
              description={node.frontmatter.description}
              excerpt={node.excerpt}
            />
          )
        })}
      </Main>
    </PageWrap>
  )
}

export default ProjectsIndex

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: {fileAbsolutePath: {regex: "/(projects)/.*\\.md$/"}}
    ) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            description
            featuredImage {
              childImageSharp {
                fluid(maxWidth: 300) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`
